import React from 'react';
import NextImage from 'next/image';
import { ADDITIONAL_PLAYERS_COUNT } from 'constants/tournaments';
import { EventStatusesEnum } from 'types/generated/client';
import { type GetTournamentsForMarketplaceQuery } from 'types/generated/server';
import Calendar from 'svg/Calendar';
import Dot from 'svg/Dot';
import Location from 'svg/Location';
import Players from 'svg/Players';
import classNames from 'styles/utils/classNames';

const DEFAULT_EVENT_IMAGE = '/images/app/default-event.png';

interface AdditionalTournamentDetails {
  startTimestamp: number;
  displayDate: string;
  distance: number;
}

type Tournament = GetTournamentsForMarketplaceQuery['events'][0] & AdditionalTournamentDetails;

interface CardProps {
  tournamentId: Tournament['id'];
  listHoveredTournamentId: string | null;
  setListHoveredTournamentId: (id: string | null) => void;
  coverImageUrl: string;
  title: Tournament['title'];
  registrationClosedAt: Tournament['registrationClosedAt'];
  startTimestamp: Tournament['startTimestamp'];
  status: Tournament['status'];
  organizerImageUrl: string;
  sourceOrganizerTitle: Tournament['sourceOrganizerTitle'];
  sourceRegistrationCount: Tournament['sourceRegistrationCount'];
  registrationCount: number;
  displayDate: Tournament['displayDate'];
  displayLocation: Tournament['displayLocation'];
  distance: Tournament['distance'];
  isExternal: Tournament['isExternal'];
  showImageAndPeople?: boolean;
}

export default function CardTournament({
  tournamentId,
  coverImageUrl,
  organizerImageUrl,
  title,
  registrationClosedAt,
  startTimestamp,
  status,
  sourceOrganizerTitle,
  sourceRegistrationCount,
  registrationCount,
  displayDate,
  displayLocation,
  distance,
  listHoveredTournamentId,
  setListHoveredTournamentId,
  isExternal,
  showImageAndPeople = true,
}: CardProps) {
  return (
    <div
      key={tournamentId}
      className={classNames(
        'w-full flex-shrink-0 overflow-hidden rounded-lg',
        listHoveredTournamentId === tournamentId &&
          'ring-2 ring-color-bg-lightmode-invert ring-offset-8 ring-offset-color-bg-darkmode-invert dark:ring-color-bg-darkmode-invert dark:ring-offset-color-bg-lightmode-invert',
      )}
      onMouseEnter={() => setListHoveredTournamentId(tournamentId)}
      onMouseLeave={() => setListHoveredTournamentId(null)}
    >
      <div className="relative">
        <NextImage
          className="relative h-48 w-full rounded-lg border border-color-border-input-lightmode object-cover object-center dark:border-color-border-input-darkmode"
          /**
           *  @todo use the internal logo
           */
          src={coverImageUrl || DEFAULT_EVENT_IMAGE}
          alt={title}
          loading="lazy"
          height={192}
          width={302}
        />
        {!registrationClosedAt && new Date(startTimestamp).getTime() < Date.now() ? (
          <span className="absolute bottom-0 right-0 rounded-br-lg rounded-tl-lg bg-color-bg-darkmode-invert px-2 py-1 text-sm">
            {status}
          </span>
        ) : status !== EventStatusesEnum.Published ? (
          <span className="absolute bottom-0 right-0 rounded-br-lg rounded-tl-lg bg-color-bg-darkmode-brand px-2 py-1 text-sm text-color-text-lightmode-invert dark:text-color-text-darkmode-invert">
            {status}
          </span>
        ) : null}
      </div>
      {showImageAndPeople && (
        <div className="my-2 flex items-center justify-between">
          {organizerImageUrl ? (
            <NextImage
              className="object-fit h-6 w-auto rounded-sm object-cover object-center"
              src={organizerImageUrl}
              alt={sourceOrganizerTitle || ''}
              loading="lazy"
              width={38}
              height={24}
            />
          ) : (
            <div className="typography-product-element-label truncate pr-1 text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder">
              {/**
               *  @todo use our data
               */}
              {sourceOrganizerTitle}
            </div>
          )}
          <p className="typography-product-button-label-xs flex shrink-0 items-center rounded-full bg-color-bg-lightmode-secondary px-ds-sm py-ds-xs text-color-text-lightmode-secondary dark:bg-color-bg-darkmode-secondary dark:text-color-text-darkmode-secondary">
            <span className="mr-1">
              <Players className="h-4 w-4" />
            </span>
            {(isExternal
              ? sourceRegistrationCount
              : (registrationCount || 0) + ADDITIONAL_PLAYERS_COUNT) || 0}{' '}
            Players
          </p>
        </div>
      )}
      <div className="mt-2 flex flex-col">
        <h3 className="typography-product-subheading mb-2 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {title}
        </h3>
        <div className="space-y-1">
          <p className="typography-product-caption flex items-center text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <span className="mr-2">
              <Calendar className="w-4 text-color-bg-lightmode-icon dark:text-color-bg-darkmode-icon" />
            </span>
            {displayDate}
          </p>
          <p className="typography-product-caption flex items-center text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <span className="mr-2">
              <Location className="w-4 text-color-bg-lightmode-icon dark:text-color-bg-darkmode-icon" />
            </span>
            {
              /**
               *  @todo use our location data
               */
              displayLocation
            }
            <span className="mx-1.5">
              <Dot className="h-1 w-1" />
            </span>
            <span className="typography-product-caption text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              {distance}mi
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
